:root {
  --primary-color: #a1011f;
  --white: #fff;
  --bg-color: #ececec;
}


#root {
  display: flex;
  flex-direction: column;
  gap: 8rem;
}

.container {
  padding-top: 2.5rem;
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr;
  gap: 3rem;
}
.right img {
  width: 100%;
  height: auto;
  max-width: 768px;
}
.left {
  text-align: center;
}

h2, h1 {
  font-weight: 500;
}

.socials {
  display: flex;
  justify-content: center;
}
.redes-sociales {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.redes-sociales a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--primary-color);
  height: 40px;
  width: 40px;
  transition: all ease-in .2s;
  font-size: 24px;
  border-radius: 50px;
  margin: 0.3rem;
}

.redes-sociales a:hover {
  background: var(--primary-color);
  color: var(--white);
}

@media (min-width:1024px) {
  h2, h1 {
      font-size: 50px;
  }
  .container {
      padding: 0 3rem;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;
  }
  .left {
      display: flex;
      padding-left: 2rem;
      max-width: 600px;
      justify-content: center;
      flex-direction: column;
      text-align: start !important;
  }
  .left p {
      font-size: 32px;
      line-height: 1;
      margin-top: 1.5rem;
  }
  .left > h2, .left > h1 {
      margin: 0;
  }
  .right img {
      max-width: none;
      width: 80%;
  }
  .right, 
  body {
      display: flex;
      justify-content: center;
      align-items: center;
  }
}

@media (max-width:1024px) {
  #root {
    gap: 2rem;
  }
}